/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Make the stepper header sticky */
.sticky-header {
  position: sticky;
  top: 0; /* Adjust this to the desired distance from the top */
  z-index: 1000; /* Ensure it stays on top of other content */
  background-color: white; /* Add background to prevent transparency */
  padding: 10px 0;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

/* Make the button section sticky */
.sticky-buttons {
  position: sticky;
  bottom: 0; /* Make it stick to the bottom */
  z-index: 1000;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); */
}